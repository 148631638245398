/*===================================
#1.3 blog-area
====================================*/
.blog-area.section-padding{
    padding-bottom: 80px;
  }
  
  .blog-item{
    display: flex;
    margin-bottom: 30px;
  }
  .blog-content {
      padding: 20px 30px;
      border: 2px solid #e7f7f0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      transition: all .3s;
  }
  .blog-content ul{
    display: flex;
    justify-content: space-between;
  }
  .blog-content ul li{
    font-size: 18px;
    font-weight: 500;
    color: #ffc039;
  }
  .blog-content ul li i{
    margin-right: 5px;
  }
  .blog-content ul li:first-child{
    color: #2db85d;
  }
  
  .blog-content h2{
    font-size: 28px;
    margin-bottom: 0;
  }
  .blog-content h2 a{
    font-size: 28px;
    font-weight: 700;
    color: #061738;
  }
  .blog-content h2 a:hover{
    color: #2db85d;
  }
  .blog-img{
    overflow: hidden;
  }
  .blog-img img{
    height: 100%;
    object-fit: cover;
    transform: scale(1);
    transition: all .3s;
  }
  
  .blog-item:hover .blog-img img{
    transform: scale(1.2);
  }
  @media(max-width: 1200px){
    .blog-content h2 a {
        font-size: 25px;
    }
  }
  @media(max-width: 540px){
    .blog-item {
        flex-wrap: wrap;
    }
    .blog-img img{
      width: 100%;
    }
    .blog-img{
        width: 100%;
    }
  
    .blog-content h2 a {
        font-size: 26px;
    }
  }