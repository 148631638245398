
/*=====================================================
#7.0 case-details-page
=======================================================*/


.case-b-text {
  margin-top: 40px;
}

#Description {
padding:30px;
 box-shadow: 0px 1px 40px 0px rgba(40, 63, 116, 0.1);
}

.tp-case-details-text .tp-case-text-top p {
  margin-bottom: 30px;
}

.tp-case-details-tab .event-b-text {
  margin-top: 40px;
}

.tp-case-details-text .tp-case-content h2 {
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 700;
}
.tp-case-content h2{
  margin-top: 20px;
}
.tp-case-details-text .tp-case-content h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.case-bb-text ul {
  display: unset;
}

.case-bb-text ul li {
  font-size: 18px;
  position: relative;
  padding-left: 20px;
  padding-bottom: 10px;
}

.case-bb-text ul li:before {
  content: "";
  background: #14b761;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 8px;
  border-radius: 50%;
}

.tp-case-details-area .tp-blog-single-section .comments-area {
  margin-top: 0;
}

