/*============================================
#1.1 volunteer-section
==============================================*/


.volunteer-content {
    text-align: center;
    padding: 20px;
    margin-top: 20px;
  }
  
  .volunteer-content h2{
    margin-top: 0;
  }
  .volunteer-content h2 a{
    font-size: 22px;
    font-weight: 700;
    color: #061738;
  }
  
  .volunteer-content h2 a:hover{
    color: #3ac060;
  }
  .volunteer-content span{
    font-size: 18px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: #787878;
  }
  
  .volunteer-wrap .volunteer-content{
       box-shadow: 0px 1px 40px 0px rgba(40, 63, 116, 0.1);
  }
  
  .volunteer-item .volunteer-img{
    transition: all .3s; 
  }
  .volunteer-item:hover .volunteer-img{
    transform: translateY(-20px);
  }