/*--------------------------------------------------------------
#0.4	hero slider
--------------------------------------------------------------*/
.hero-slider {
    width: 100%;
    height:807px;
    position: relative;
    z-index: 0;
  }

  .slide{
    height: 800px;
  }

  .slide-shape {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: -1;
  }
  
  @media (max-width: 991px) {
    .hero-slider {
      height: 700px;
    }
    .slide{
      height: 700px;
    }
    .slide-shape{
      display: none;
    }
  }
  
  @media (max-width: 767px) {
    .hero-slider {
      height: 600px;
    }

    .slide{
      height: 600px;
    }
  
    .slide-shape{
      display: none;
    }
    .hero-style-1 .slide-title h2{
      margin-bottom: 0;
    }
  }
  @media (max-width: 570px) {
    .theme-btn-s2{
      display: none;
    }
  }
  
  
  .hero-slider .swiper-slide {
    overflow: hidden;
    color: #fff;
  }
  
  .hero-slider .swiper-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .hero-slider .slide-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
  }
  

  .hero-slider .slick-dots {
    bottom: 35px;
    right: 20%;
    width: auto;
    top: 43%;
  }
  
  .hero-slider .slick-dots li {
    margin: 10px;
    display: block;
  }
  
  .slick-dots li.slick-active button:before {
      opacity:1;
      color: #e9bf3d;
      border:1px solid #e9bf3d;
      border-radius: 100%;
  }
  .slick-dots li button:hover:before, .slick-dots li button:focus:before {
      opacity: 1;
  }
  
  .slick-dots li button:before {
      font-size: 12px;
      line-height: 25px;
      width: 25px;
      height: 25px;
      color: #13b761;
      opacity: 1;
  }
  
  
  @media(max-width:991px){
    .hero-slider .slick-dots {
      bottom: 35px;
      right: auto;
      width: auto;
      top: auto;
      left: 50%;
      transform: translateX(-50%);
    }
    .hero-slider .slick-dots li {
      margin: 10px;
      display: inline-block;
    }
  }


  /*--------------------------------------------------------------
      #hero-style-1
  --------------------------------------------------------------*/
  .hero-style-1 .slide-thumb {
    width: 175px;
    height: 70px;
    line-height: 58px;
    text-align: center;
    background: url(../../images/shape/comment.png) center center/cover no-repeat local;
  }
  .hero-style-1 .slide-thumb span{
    font-size: 20px;
    color: #fff;
  }
  
  .hero-style-1 .slide-title {
    max-width: 670px;
    margin-top: 10px;
  }
  
  .hero-style-1 .slide-title h2 {
    font-size:100px;
    color: #fff;
    margin: 0 0 0.43em;
    line-height: 100px;
    font-weight: 600;
  }
  
  @media (max-width: 991px) {
    .hero-style-1 .slide-title h2 {
      font-size: 45px;
      line-height: 55px;
  
    }
  }
  
  @media (max-width: 767px) {
    .hero-style-1 .slide-title h2 {
      font-size: 35px;
      line-height: 45px;
    }
  
    .header-style-2 .navigation .navbar-brand,
    .header-style-3 .navigation .navbar-brand {
      font-size: 30px;
  }
  
  }
  @media (max-width: 530px) {
  .tp-site-header .cart-search-contact {
      right: 55px;
  }
  .hero-style-1 .slide-title h2 {
      font-size: 26px;
  }
  }
  
  .hero-style-1 .slide-title h2 span {
    display: inline-block;
    font-style: italic;
    position: relative;
  }
  
  .hero-style-1 .slide-title h2 span:before {
    content: "";
    background-color: rgba(193, 14, 14, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 5px;
    z-index: -1;
  }
  
  .hero-style-1 .slide-text {
    max-width: 490px;
    position: relative;
    z-index: 50;
  }
  
  .hero-style-1 .slide-text p {
    color: #fff;
    margin: 0 0 2.5em;
  }
  
  .hero-style-1 .slide-btns {
    position: relative;
    z-index: 50;
  }
  
  
  @media (max-width: 767px) {
    .hero-style-1 .slide-btns:after {
      display: none;
    }
  }
